import React from "react"
import { graphql } from "gatsby"

import { Container, Col, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import otherEssSpotlight from "../media/img/development/Other-essentials-spotlight.jpg"
import Wiedenism from "../components/Wiedenism"

export default function OtherEssensials(props) {
  // console.log(props)
  return (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Other Essentials" />
    <SpotlightArticle tags={`Other Essentials`} title={`This is the home for all the other essentials you should know about. Head here to take a look at the jobs board for open positions you’d like to refer a friend to. This is also where you can go if you ever need to safely speak up about harassment or bias in the workplace.`} bgImg={otherEssSpotlight} borderColor="#af14ba" />
    <section>
      <Container>
        <Row>
          
            {props.data.allIntranetPages.edges.map(pagesThumb => {
              return(
                <Col xs={12} lg={4}>
                <div className="news--content">
                    <a rel="noreferrer" href={pagesThumb.node.slug}>
                      <div className="image">
                        <img src={pagesThumb.node.image} alt="" />
                      </div>
                      <div className="tag header--ess">
                        {pagesThumb.node.title}
                      </div>
                      <div className="header">
                        {pagesThumb.node.excerpt}
                      </div>
                      <small>View</small>
                    </a>
                </div>
                </Col>
              )
            })}            
            {props.data.allIntranetPost.edges.map(article => {
              return(
                <Col xs={12} lg={4}>
                <div className="news--content">
                  {article.node.type === "external" && (
                    <a target="_blank" rel="noreferrer" href={article.node.link}>
                      <div className="image">
                        <img src={article.node.image} alt="" />
                      </div>
                      <div className="tag header--ess">
                        {article.node.title}
                      </div>
                      <div className="header">
                        {article.node.excerpt}
                      </div>
                      <small>View</small>
                    </a>
                  )}
                </div>
                </Col>
              )
            })}            
          
          
          </Row>

      </Container>
      <Wiedenism />
    </section>
  </Layout>
   )
}


export const query = graphql`
  query OtherEssensials {
    allIntranetPost(filter: {category: {eq: "Other Essentials"}}) {
      edges {
        node {
          category
          excerpt
          image
          link
          title
          type
        }
      }
    }
    allIntranetPages(filter: {category: {eq: "others"}}) {
      edges {
        node {
          slug
          title
          image
          excerpt
          category
        }
      }
    }
  }
`